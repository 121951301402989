import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Hydro from "/src/components/img/hydro"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

import Hydroo1 from "/src/components/img/hydroo/1"
import Hydroo2 from "/src/components/img/hydroo/2"


export default () => (
  <Layout>
    <SEO title="Mokřady" />
    <h1>Mokřady</h1>
    <p><strong>Mokřad </strong>je &uacute;zem&iacute; na zemsk&eacute;m povrchu, kter&eacute; je trvale či vět&scaron;&iacute; č&aacute;st roku zamokřen&eacute;, př&iacute;padně mělce zaplaven&eacute; vodou a zarostl&eacute; vlhkomilnou a vodomilnou vegetac&iacute;, přičemž pr&aacute;vě vegetačn&iacute; kryt je v&yacute;razn&yacute;m rozli&scaron;ovac&iacute;m znakem.</p>
    <p>Jde o <strong>biotop </strong>specifick&yacute; v&yacute;skytem organismů vyžaduj&iacute;c&iacute;ch ke sv&eacute; existenci a prosperitě st&aacute;l&yacute; <strong>&uacute;činek povrchov&eacute; vody nebo alespoň velmi vysok&eacute; hladiny podzemn&iacute; vody</strong> a tvoř&iacute;c&iacute; přechod mezi suchozemsk&yacute;m a vodn&iacute;m ekosyst&eacute;mem.</p>
    <hr /><h2>Klasifikace mokřadů</h2>
    <p>Mokřady lze např&iacute;klad rozli&scaron;it dle <strong>vegetačn&iacute;ho krytu</strong>, a to n&aacute;sledovně:</p>
    <ul>
    <li><strong>mokřady ekvatori&aacute;ln&iacute;ch oblast&iacute; (</strong>porosty de&scaron;tn&yacute;ch lesů, trav i vodn&iacute;ch rostlin, na pobřež&iacute; moř&iacute; s mangrovov&yacute;mi porosty),</li>
    <li><strong>mokřady vlhk&yacute;ch tropů a subtropů </strong>(porosty r&aacute;kosů, trav ale i vysok&yacute;ch dřevin (např. blahovičn&iacute;ky, bahenn&iacute; cypři&scaron;e),</li>
    <li><strong>mokřady such&yacute;ch tropů a subtropů </strong>(nahromaděn&aacute; sůl a slanomiln&aacute; vegetace; př&iacute;klady slan&yacute;ch p&aacute;nv&iacute; &ndash; playas, salinas),</li>
    <li><strong>mokřady m&iacute;rn&eacute;ho p&aacute;sma </strong>(mnoho možn&yacute;ch klasifikac&iacute;, např. typy ra&scaron;elini&scaron;ť: slatini&scaron;tě, přechodn&aacute; ra&scaron;elini&scaron;tě a vrchovi&scaron;tě).</li>
    </ul>
    <p>V&nbsp;na&scaron;ich podm&iacute;nk&aacute;ch, m&iacute;rn&yacute;ch &scaron;&iacute;řk&aacute;ch, lze rozli&scaron;it mokřady <strong>dle způsobu z&aacute;sobov&aacute;n&iacute;</strong> na:</p>
    <ul>
    <li><strong>moč&aacute;lov&yacute; syst&eacute;m mokřadů,</strong></li>
    <li><strong>ř&iacute;čn&iacute; syst&eacute;m,</strong></li>
    <li><strong>jezern&iacute; syst&eacute;m.</strong></li>
    </ul>
    <p>V&nbsp;každ&eacute;m ve v&yacute;&scaron;e zm&iacute;něn&eacute;m syst&eacute;mu je typick&aacute; lesn&iacute;, křovinn&aacute; a bylinn&aacute; formace.</p>
    <hr /><h2>Ra&scaron;elini&scaron;tě</h2>
    <p><strong>Ra&scaron;elini&scaron;tě</strong> (tj. blata či slatě) je forma mokřadů s&nbsp;v&yacute;skytem ra&scaron;eliny. Odumřel&eacute; č&aacute;sti rostlinn&eacute;ho společenstva se shromažďuj&iacute; a ve spodn&iacute;ch vrstv&aacute;ch a za nepř&iacute;stupu vzduchu se přetv&aacute;řej&iacute; na <strong>ra&scaron;elinu</strong>. Rozli&scaron;ujeme:</p>
    <ul>
    <li><strong>slatini&scaron;tě </strong>(řeka či podzemn&iacute; voda přin&aacute;&scaron;&iacute; dostatek miner&aacute;ln&iacute;ch l&aacute;tek, vznikaj&iacute; zan&aacute;&scaron;en&iacute;m a zarůst&aacute;n&iacute;m jezer, mrtv&yacute;ch ř&iacute;čn&iacute;ch ramen a na nejniž&scaron;&iacute;ch č&aacute;stech zaplavovan&yacute;ch &uacute;dol&iacute; řek, nejvy&scaron;&scaron;&iacute; pH)<strong>,</strong></li>
    <li><strong>přechodn&eacute; ra&scaron;elini&scaron;tě </strong>(m&iacute;sta s nedokonal&yacute;m odtokem vody a vysokou hladinou podzemn&iacute; vody, m&eacute;ně živin d&iacute;ky vět&scaron;&iacute;mu pod&iacute;lu vody ze sr&aacute;žek),</li>
    <li><strong>vrchovi&scaron;tě </strong>(vypoukl&aacute;, na hor&aacute;ch, nap&aacute;jena v&yacute;hradně sr&aacute;žkovou vodou, často mal&aacute; jez&iacute;rka s hnědě až rezatě zabarvenou vodou, nejniž&scaron;&iacute; pH, nejm&eacute;ně živin)<strong>.</strong></li>
    </ul>
    <p><strong>Ra&scaron;elina</strong> byla i je často těžena pro sv&eacute; užit&iacute; jako fosiln&iacute; palivo, izolace, v&nbsp;zemědělstv&iacute; a zahradnictv&iacute;, pro bahenn&iacute; z&aacute;baly a dal&scaron;&iacute;. Dř&iacute;ve doch&aacute;zelo tak&eacute; k&nbsp;odvodňov&aacute;n&iacute; a likvidaci (nejen) ra&scaron;elini&scaron;ť pro z&iacute;sk&aacute;n&iacute; hospod&aacute;řsk&eacute; půdy (např. pro lesy).</p>
    <p>Ra&scaron;elini&scaron;tě jsou v&scaron;ak jakožto vz&aacute;cn&yacute; ekosyst&eacute;m v&nbsp;dne&scaron;n&iacute; době přev&aacute;žně chr&aacute;něna. Jejich obnova totiž trv&aacute; stalet&iacute; a jsou domovem unik&aacute;tn&iacute;ch společenstev. Rovněž maj&iacute; v&yacute;znamnou <strong>retenčn&iacute; kapacitu</strong>, ra&scaron;elin&iacute;ky zadrž&iacute; vodu o až 10-25 n&aacute;sobku sv&eacute; v&aacute;hy.</p>
    <hr />
    <ImgCon><Hydro /><div>
    <h2>Studijn&iacute; materi&aacute;ly Hydrologie</h2>
    <p>T&eacute;ma <strong>Mokřady</strong> je souč&aacute;st&iacute; <Link to="/hydrologie/">studijn&iacute;ch materi&aacute;lů Hydrologie a hydrogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předešlé (2.&nbsp;tlač&iacute;tko) či následující (3. tlačítko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/hydrologie/"><button>Rozcestník hydrologie</button></Link>
    <Link to="/hydrologie/vodni-nadrze/"><button className="inv">&larr; Vodní nádrže</button></Link>
    <Link to="/hydrologie/svetovy-ocean/"><button className="inv">Světový oceán &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
